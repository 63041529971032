<template>
    <div class="home">
        <div :class="{'navigate-body': true, 'navigate-body-one': ispageone}">
			<div class="logo-wraper">
				<el-image v-show="ispageoneLogo" @click="openHome" class="logo" :src="require('../assets/images/logo2.png')"/>
				<el-image v-show="!ispageoneLogo" @click="openHome" class="logo" :src="require('../assets/images/logo1.png')"/>
			</div>
            <div class="navigate-btns">
                <div class="nav-btn active-nav-btn">首页</div>
                <div class="nav-btn" @click="openPcPage">电脑端学习</div>
                <div class="nav-btn" @click="openDownload">应用下载</div>
                <div class="nav-btn" @click="openSLW()">书联网</div>
                <div class="nav-btn" @click="openAboutUs">关于我们</div>
            </div>
        </div>

		<div class="video-player-wraper" v-if="showVideoDialog">
			<div class="video-player">
				<div class="video-close"  @click="closeVideoDialog">
					<img src="../assets/images/videoClose.png" alt="">
				</div>

				<video id="dialogVideo" class="dialog-video"
				       src="https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/pcVideo02.mp4"
				       autoplay
					   loop
					   width="100%"
					   controls="controls"
					   >
				    您的浏览器不支持 video 标签。
				</video>
			</div>
		</div>

        <full-page ref="fullpage" :options="options" id="fullpage">
            <div class="section">
                <div class="home-body">
                    <video id="home-video" class="home-video"
                           src="https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/pcVideo01.mp4"
                           autoplay
                           muted loop width="100%">
                        您的浏览器不支持 video 标签。
                    </video>

                    <div class="video-mask">
                        <div class="video-play-btn" @click="openVideoDialog">
                            <img src="../assets/images/play-icon.png" alt="">
                        </div>
                        <div class="video-msg">
                            <div class="download-btn" @click="openDownApp">下载APP</div>
                            <div class="video-text">致力于以纸质产品增值服务为宗旨，力求为学生解决课前预习难，课中听不懂，课后想复习等一系列学习难题。</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="course-wraper">
                    <div :class="{'course-title': true, 'animate__animated': true, 'animate__fadeInUp': featureAnimate}">
                        课程特色
                    </div>
                    <div :class="{'course-list': true, 'animate__animated': true, 'animate__fadeInUp': featureAnimate}">
                        <div class="course-item" v-for="(item, index) in courseList" :key="index">
                            <img class="course-item-bc" :src="item.bac" alt="">
                            <div class="course-item-body">
                                <div class="course-item-title">{{ item.title }}</div>
                                <div class="course-item-text">{{ item.text }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="course-bac">
                        <img src="../assets/images/course-bac.gif" alt="">
                    </div>
                </div>
            </div>
            <!-- 课程形式多样 -->
            <div class="section">
                <div class="various-wraper">
                    <div :class="{'various-title': true, 'animate__animated': true, 'animate__fadeInUp': courseAnimate}">
                        课程多样性
                    </div>
                    <el-image fit="cover" class="various-bac" :src="require('../assets/images/various-bac.png')"/>
                    <el-image fit="cover"
                              :class="{'various-bac-top': true, 'various-bac-animation': variousBacAnimation}"
                              :src="require('../assets/images/various-bac-top.png')"/>

                    <img :class="{'various-icon': true, 'various-icon-a': true, 'various-icon-animate': courseAnimate }"
                         src="../assets/images/various-icon-a.png" alt="">
                    <img :class="{'various-icon': true, 'various-icon-b': true, 'various-icon-animate': courseAnimate }"
                         src="../assets/images/various-icon-b.png" alt="">
                    <img :class="{'various-icon': true, 'various-icon-c': true, 'various-icon-animate': courseAnimate }"
                         src="../assets/images/various-icon-c.png" alt="">
                    <img :class="{'various-icon': true, 'various-icon-d': true, 'various-icon-animate': courseAnimate }"
                         src="../assets/images/various-icon-d.png" alt="">
                    <img :class="{'various-icon': true, 'various-icon-e': true, 'various-icon-animate': courseAnimate }"
                         src="../assets/images/various-icon-e.png" alt="">
                    <img :class="{'various-icon': true, 'various-icon-f': true, 'various-icon-animate': courseAnimate }"
                         src="../assets/images/various-icon-f.png" alt="">
                    <img :class="{'various-icon': true, 'various-icon-g': true, 'various-icon-animate': courseAnimate }"
                         src="../assets/images/various-icon-g.png" alt="">
                </div>
            </div>

            <!-- footer -->
            <div class="section">
                <div class="home-footer" id="home-footer">
                    <div class="home-footer-wraper">
                        <div :class="{'foot-title': true, 'animate__animated': true, 'animate__fadeInUp': partnerAnimate}">
                            合作伙伴
                        </div>

                        <div :class="{'supplier-list': true, 'animate__animated': true, 'animate__fadeInUp': partnerAnimate}">
                            <div class="supplier-item">
                                <div class="supplier-item-cover">
                                    <img class="square" src="../assets/images/supplier-000.png" alt="">
                                </div>
                                <p>新学未</p>
                            </div>
                            <div class="supplier-item">
                                <div class="supplier-item-cover">
                                    <img class="square" src="../assets/images/supplier-001.png" alt="">
                                </div>
                                <p>看鉴教育</p>
                            </div>
                            <div class="supplier-item">
                                <div class="supplier-item-cover">
                                    <img class="square" src="../assets/images/supplier-002.png" alt="">
                                </div>
                                <p>山姆英语</p>
                            </div>
                            <div class="supplier-item">
                                <div class="supplier-item-cover">
                                    <img class="square" src="../assets/images/supplier-003.png" alt="">
                                </div>
                                <p>英语爆破音</p>
                            </div>
                            <div class="supplier-item">
                                <div class="supplier-item-cover">
                                    <img class="square" src="../assets/images/supplier-004.png" alt="">
                                </div>
                                <p>王洪涛硬笔书法课堂</p>
                            </div>
                            <div class="supplier-item">
                                <div class="supplier-item-cover">
                                    <img class="square" src="../assets/images/supplier-005.png" alt="">
                                </div>
                                <p>王佳一教育</p>
                            </div>
                            <div class="supplier-item">
                                <div class="supplier-item-cover">
                                    <img class="square" src="../assets/images/supplier-006.png" alt="">
                                </div>
                                <p>逗你学</p>
                            </div>
                            <div class="supplier-item">
                                <div class="supplier-item-cover">
                                    <img class="square" src="../assets/images/supplier-007.png" alt="">
                                </div>
                                <p>明见少年</p>
                            </div>
                            <div class="supplier-item">
                                <div class="supplier-item-cover">
                                    <img class="square" src="../assets/images/supplier-011.png" alt="">
                                </div>
                                <p>光锥文化</p>
                            </div>
                        </div>
                        <div class="foot-bac-icon">
                            <img src="../assets/images/foot-bac-icon.gif" alt="">
                        </div>
                        <div class="foot-list-wrap">
                            <div class="foot-list">
                                <div class="foot-item">
                                    <img src="../assets/images/foot-item-a.png" alt="">
                                    <p class="foot-item-title">内容合作</p>
                                    <p class="foot-item-text">精品内容资源对接，扩大曝光</p>
                                </div>
                                <div class="foot-item">
                                    <img src="../assets/images/foot-item-b.png" alt="">
                                    <p class="foot-item-title">广告合作</p>
                                    <p class="foot-item-text">精准品牌营销方案，广而告之</p>
                                </div>
                                <div class="foot-item">
                                    <img src="../assets/images/foot-item-c.png" alt="">
                                    <p class="foot-item-title">代理合作</p>
                                    <p class="foot-item-text">打通各路推广渠道，互利互惠</p>
                                </div>
                                <div class="foot-item">
                                    <img src="../assets/images/foot-item-d.png" alt="">
                                    <p class="foot-item-title">平台合作</p>
                                    <p class="foot-item-text">跨平台流量、服务协作，优势互补</p>
                                </div>
                            </div>
                            <div v-if="false" :class="{'foot-list-btn': true, 'animate__animated': true, 'animate__fadeInUp': pageFootAnimate}" @click="contactUs">
                              联系我们：4000659885
                            </div>
                        </div>

                        <copyright-notice class="foot-bottom"/>
                    </div>
                </div>
            </div>
        </full-page>
    </div>
</template>

<script>
    import $ from 'jquery';
    import CopyrightNotice from '../components/Layout/CopyrightNotice.vue'

    export default {
        name: 'Home',
        components: { CopyrightNotice },
        data() {
            return {
                options: {
                    licenseKey: 'YOUR_KEY_HEERE',
                    menu: '#menu',
                    anchors: ['intro', 'feature', 'course', 'partner'],
                    sectionsColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff'],
                    navigation: true,
                    navigationPosition: 'right',
                    verticalCentered: false,
                    scrollOverflow: true,
                    paddingTop: "0px"
                },
                scrollTopVal: 0,
                videoSrc: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/pcVideo01.flv',
                courseList: [{
                    title: '全程陪伴',
                    text: '淘知学堂课程涵盖小学、初中、高中各学年段，共2万余节课程。一路陪伴，如师如友。',
                    bac: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/images/course-item-a.png'
                },
                    {
                        title: '自主挑选',
                        text: '淘知学堂课程涵盖90%以上版本，与课内教材同步，可自主挑选学习内容。适合自己，才是最好。',
                        bac: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/images/course-item-b.png'
                    },
                    {
                        title: '内容丰富',
                        text: '淘知学堂课程丰富有料，有动漫解析课，有各种录播课。长的不到一个小时，短的只有几分钟。在轻松中学习，在趣味中提升。',
                        bac: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/images/course-item-c.png'
                    },
                    {
                        title: '随看随学',
                        text: '淘知学堂支持手机、平板、电视多端同步学习。乘车、课间、餐前，随时随地，想学就学，真方便。',
                        bac: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/images/course-item-d.png'
                    },
                ],
                featureAnimate: false,
                courseAnimate: false,
                teamAnimate: false,
                partnerAnimate: false,
                pageFootAnimate: false,
                variousBacAnimation: false,
				showVideoDialog: false,
				ispageone: true,
				ispageoneLogo: true,
            };
        },
        watch: {
            $route: {
                handler: function (val, oldVal) {
                    let player = document.getElementById("home-video");
                    if (val.hash === '#intro') {
                        // 继续播放
                        player.play();
                        this.featureAnimate = false; // 控制第二页的上入动画
                        this.courseAnimate = false;
                        this.teamAnimate = false;
                        this.partnerAnimate = false;
                        this.variousBacAnimation = false; // 控制第三页的旋转动画
                        this.changeNavColor(
                            'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/images/nav-active-icon.png',
                            'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/images/nav-icon.png',
                        );
						this.ispageone = true;
						this.ispageoneLogo = true;
                    } else if (val.hash === '#feature') {
                        // 暂停
                        player.pause();
                        setTimeout(() => {
                            this.featureAnimate = true;
                        }, 400);
                        this.courseAnimate = false;
                        this.teamAnimate = false;
                        this.partnerAnimate = false;
                        this.variousBacAnimation = false;
                        this.changeNavColor(
                            'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/images/nav-active-icon-a.png',
                            'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/images/nav-icon-a.png',
                        );
						this.ispageone = false;
						setTimeout(()=>{
							this.ispageoneLogo = false;
						}, 400);
                    } else if (val.hash === '#course') {
                        this.featureAnimate = false;
                        this.teamAnimate = false;
                        this.partnerAnimate = false;
                        this.variousBacAnimation = false;
                        this.courseAnimate = true;
                        this.changeNavColor(
                            'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/images/nav-active-icon.png',
                            'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/images/nav-icon.png',
                        );
						this.ispageone = false;
						setTimeout(()=>{
							this.ispageoneLogo = false;
						}, 400);
                    } else if (val.hash === '#team') {
                        this.featureAnimate = false;
                        this.courseAnimate = false;
                        this.teamAnimate = true;
                        this.partnerAnimate = false;
                        this.variousBacAnimation = true;
                        this.changeNavColor(
                            'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/images/nav-active-icon-a.png',
                            'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/images/nav-icon-a.png',
                        );
						this.ispageone = false;
						setTimeout(()=>{
							this.ispageoneLogo = false;
						}, 400);
                    } else if (val.hash === '#partner') {
                        this.featureAnimate = false;
                        this.courseAnimate = false;
                        this.teamAnimate = false;
                        setTimeout(() => {
                            this.partnerAnimate = true;
                        }, 400);
                        this.variousBacAnimation = false;
                        this.changeNavColor(
                            'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/images/nav-active-icon-a.png',
                            'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/images/nav-icon-a.png',
                        );
						this.ispageone = false;
						setTimeout(()=>{
							this.ispageoneLogo = false;
						}, 400);
                    }
                },
                // 深度观察监听
                deep: true
            },
			showVideoDialog(newVal) {
				if(newVal) {
					this.$refs.fullpage.api.setAllowScrolling(false, 'down');
				} else {
					this.$refs.fullpage.api.setAllowScrolling(true, 'down');
				}
			}
        },
        mounted() {
            this.eventScroll();
			window.document.title = '淘知学堂 - 首页'
			window.location.hash = '#intro'
        },
        methods: {
      openSLW() {
        window.open('https://mp.taozhi.cn/publicity/home.html')
      },
			openHome() {

			},
      openPcPage() {
        window.open('https://www.taozhi.cn/pcweb/')
      },
			openVideoDialog() {
				this.showVideoDialog = true;
			},
			closeVideoDialog() {
				this.showVideoDialog = false;
			},
      openAboutUs() {
          this.$router.push({ path: '/aboutus' })
      },
      openDownload() {
          this.$router.push({ path: '/download' })
      },
      /**
       * 监听最后一屏滚动事件
       */
      eventScroll() {
          document.getElementById('home-footer').addEventListener('scroll', () => {
              this.scrollTopVal = document.getElementById('home-footer').scrollTop;
              if (this.scrollTopVal > 0) {
                  this.$refs.fullpage.api.setAllowScrolling(false, 'up');
                  if (this.scrollTopVal > 300) {
                      this.pageFootAnimate = true;
                  } else {
                      this.pageFootAnimate = false;
                  }
              } else {
                  this.$refs.fullpage.api.setAllowScrolling(true, 'up');
              }
          });
      },
      /**
       * 改变导航颜色
       */
      changeNavColor(icon, smIcon) {
          $('.fp-slidesNav ul li a span').css({
              backgroundImage: `url(${smIcon})`
          });
          $('#fp-nav ul li a span').css({
              backgroundImage: `url(${smIcon})`
          });

          $('#fp-nav ul li a.active span').css({
              backgroundImage: `url(${icon})`
          })
          $('#fp-nav ul li:hover a.active span').css({
              backgroundImage: `url(${icon})`
          })
          $('.fp-slidesNav ul li a.active span').css({
              backgroundImage: `url(${icon})`
          })
          $('.fp-slidesNav ul li:hover a.active span').css({
              backgroundImage: `url(${icon})`
          })
      },
			/**
			 * 打开下载页面
			 */
			openDownApp() {
				this.$router.push({
					path: '/download'
				})
				// window.open('https://m3w.cn/tzxt');
			},
			/**
			 * 联系我们
			 */
			contactUs() {
				window.open('https://www.taozhi.cn/mq_chat.htm')
			}
        }
    };
</script>
<style>
    .fp-tableCell {
        display: block;
    }

    #fp-nav ul li,
    .fp-slidesNav ul li {
        width: 50px;
        height: 50px;
    }

    #fp-nav ul li a,
    .fp-slidesNav ul li a {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* 未选中的导航样式 */
    #fp-nav ul li a span,
    .fp-slidesNav ul li a span {
        width: 12px;
        height: 12px;
        background-color: transparent;
        background-image: url(https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/images/nav-icon.png);
        -webkit-background-size: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        left: 19px;
        top: 19px;
        margin: 0;
        transition: all 0.1s !important;
    }

    #fp-nav ul li a:hover span,
    .fp-slidesNav ul li a:hover span {
        margin: 0;
        width: 12px;
        height: 12px;
        left: 19px;
        top: 19px;
    }

    /* 选中的导航样式 */
    #fp-nav ul li a.active span,
    #fp-nav ul li:hover a.active span,
    .fp-slidesNav ul li a.active span,
    .fp-slidesNav ul li:hover a.active span {
        margin: 0;
        width: 32px;
        height: 32px;
        left: 9px;
        top: 9px;
        background-color: transparent;
        background-image: url(../assets/images/nav-active-icon.png);
        background-repeat: no-repeat;
        -webkit-background-size: 32px;
        background-size: 32px;
    }
</style>
<style scoped lang="scss">
    .navigate-body {
        width: 100%;
        height: 80px;
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        padding: 0 120px;
        box-sizing: border-box;
        -webkit-box-shadow: 0px 8px 6px 5px #cbecff;
        -moz-box-shadow: 0px 8px 6px 5px #cbecff;
        box-shadow: 0px 8px 6px 0px rgba(203, 236, 255, 0.2);
        display: flex;
        justify-content: space-between;
		transition: background-color 0.4s;

		.logo-wraper {
			width: 176px;
			height: 113px;
			overflow: hidden;
		}
        .logo {
            width: 185px;
			cursor: pointer;
			fliter: drop-shadow(0px 1px 1px #edf8ff);
			-webkit-filter: drop-shadow(0px 1px 1px #edf8ff);
        }

        .navigate-btns {
            display: flex;
            height: 80px;
			padding: 15px 0;

            .nav-btn {
                height: 50px;
                line-height: 50px;
                margin: 0 20px;
                font-size: 18px;
                color: #2A536B;
                cursor: pointer;
				background-image: url("../assets/images/nav-icon.png");
				background-repeat: no-repeat;
				background-size: 24px 4px;
				background-position: center 60px;
				transition: all .3s;
            }

            .nav-btn:hover {
                color: #00A2FF;
                background-position: center 46px;
            }

            .active-nav-btn {
                color: #00A2FF;
                background-image: url("../assets/images/nav-icon.png");
                background-repeat: no-repeat;
                background-size: 24px 4px;
                background-position: center 46px;
            }
        }
    }

	.navigate-body-one {
		background-color: rgba($color: #ffffff, $alpha: 0);
		box-shadow: none;
		.navigate-btns {
		    .nav-btn {
		        color: #ffffff;
				background-image: url("../assets/images/nav-icon-a.png");
		    }

		    .nav-btn:hover {
		        color: #ffffff;
		    }

		    .active-nav-btn {
		        color: #ffffff;
		        background-image: url("../assets/images/nav-icon-a.png");
		    }
		}
	}

    .home-video {
        width: 100%;
        object-fit: fill;
    }

    .home-body {
        width: 100%;
        height: 100%;

        .video-mask {
            width: 100%;
            height: 100%;
			background-color: rgba($color: #000000, $alpha: 0.3);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
        }

        .video-play-btn {
            width: 96px;
            height: 96px;
            margin: auto;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .video-msg {
            width: 595px;
            position: absolute;
            left: 135px;
            bottom: 100px;

            .download-btn {
                width: 200px;
                height: 60px;
                color: #00A2FF;
                font-size: 28px;
                text-align: center;
                line-height: 60px;
                background-color: #FFFFFF;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                border-radius: 30px;
                margin-bottom: 30px;
                cursor: pointer;
				transition: all .5s;
            }
			.download-btn:hover {
				box-shadow: 1px 1px 5px 3px rgba($color: #cbecff, $alpha: .2);
			}

            .video-text {
                font-size: 18px;
                color: #ffffff;
                line-height: 32px;
            }
        }
    }

    /* 课程特色 */
    .course-wraper {
        height: 100%;
		padding-top: 80px;

        .course-title {
            font-size: 48px;
            color: #2A536B;
            text-align: center;
            padding: 80px 0 60px 0;
            line-height: 1;
            position: relative;
            z-index: 2;
            opacity: 0;
        }

        .course-list {
            height: 490px;
            display: flex;
            justify-content: center;
            position: relative;
            z-index: 2;
            opacity: 0;

            .course-item {
                width: 276px;
                height: 490px;
                position: relative;
                margin: 0 16px;

                .course-item-bc {
                    width: 100%;
                    height: 100%;
                }

                .course-item-body {
                    width: 276px;
                    height: 490px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 2;
                    padding: 0 25px;
                    box-sizing: border-box;
                }

                .course-item-title {
                    font-size: 32px;
                    font-weight: bold;
                    color: #ffffff;
                    text-align: center;
                    line-height: 1;
                    padding: 40px 0 30px 0;
                }

                .course-item-text {
                    font-size: 18px;
                    color: #ffffff;
                    line-height: 32px;
                }
            }
        }

        .course-bac {
            width: 100%;
            position: absolute;
            bottom: 0px;
            z-index: 1;
        }
    }

    /* 课程形式多样 */
    .various-wraper {
        width: 100%;
        height: calc(100% - 80px);
        position: relative;
		top: 80px;

        .various-bac {
            width: 100%;
            height: 100%;
        }

        .various-bac-top {
            width: 1920px;
            position: absolute;
            left: 0;
            top: 0;
        }

        .various-bac-animation {
            animation: 1s bacRotate;
        }

        .various-title {
            font-size: 48px;
            color: #ffffff;
            text-align: center;
            position: absolute;
            top: 80px;
            width: 100%;
            z-index: 2;
        }

        .various-icon {
            position: absolute;
            opacity: 0;
            transition: opacity 2.5s;
        }

        .various-icon-d {
            width: 103px;
            height: 111px;
            left: calc(50% - 51px);
            top: 220px;
			animation: topBottom 1s ease-in-out infinite alternate;
        }

        .various-icon-b {
            width: 116px;
            height: 114px;
            left: calc(25% - 58px);
            top: 220px;
			animation: bottomTop 1s ease-in-out infinite alternate;
        }

        .various-icon-f {
            width: 94px;
            height: 100px;
            left: calc(75% - 47px);
            top: 285px;
			animation: bottomTop 1s ease-in-out infinite alternate;
        }

        .various-icon-a {
            width: 132px;
            height: 125px;
            left: 10%;
            top: 464px;
			animation: topBottom 1s ease-in-out infinite alternate;
        }

        .various-icon-c {
            width: 105px;
            height: 111px;
            left: 35%;
            top: 320px;
			animation: topBottom 1s ease-in-out infinite alternate;
        }

        .various-icon-e {
            width: 126px;
            height: 119px;
            left: 60%;
            top: 317px;
			animation: bottomTop 1s ease-in-out infinite alternate;
        }

        .various-icon-g {
            width: 130px;
            height: 128px;
            left: 80%;
            top: 490px;
			animation: bottomTop 1s ease-in-out infinite alternate;
        }

        .various-icon-animate {
            opacity: 1;
        }
    }


	@keyframes topBottom {
	    0% {
	       transform: translateY(0px);
	    }

	    100% {
	        transform: translateY(10px);
	    }
	}

	@keyframes bottomTop {
	    0% {
	       transform: translateY(0px);
	    }

	    100% {
	        transform: translateY(-10px);
	    }
	}

    @keyframes bacRotate {
        0% {
            transform: rotate(0)
        }

        100% {
            transform: rotate(-30deg)
        }
    }

    @keyframes various-show {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    /* 研发团队 */
    .team-wraper {
        width: 100%;
        height: calc(100% - 80px);
        position: relative;
        top: 80px;

        .team-title {
            font-size: 48px;
            color: #2A536B;
            text-align: center;
            padding: 80px 0 40px 0;
            line-height: 1;
            position: relative;
            z-index: 2;
        }

        .team-bac {
            width: 1436px;
            height: 468px;
            margin: 0 auto;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .team-text {
            margin: 0 auto;
            width: 933px;
            font-size: 18px;
            color: #2A536B;
            text-align: center;
            padding-top: 50px;
            line-height: 32px;
        }

        .team-icon-a {
            width: 54px;
            height: 25px;
            position: absolute;
            top: 80px;
            left: -54px;
            transition: left 1s;
        }

        .team-icon-a-show {
            left: 568px;
        }

        .team-icon-b {
            width: 75px;
            height: 36px;
            position: absolute;
            top: 133px;
            right: -75px;
            transition: right 1s;
        }

        .team-icon-b-show {
            right: 271px;
        }

        .team-icon-c {
            width: 160px;
            height: 75px;
            position: absolute;
            top: 170px;
            left: -160px;
            transition: left 1s;
        }

        .team-icon-c-show {
            left: 265px;
        }
    }

    .home-footer {
        width: 100%;
        height: calc(100% - 80px);
        overflow: auto;
		position: relative;
		top: 80px;

        .home-footer-wraper {
            width: 100%;
            height: 1337px;
            background-image: url(../assets/images/foot-bac.png);
            background-repeat: no-repeat;
            -webkit-background-size: 1920px;
            background-size: 1920px;
            background-position: left 270px;
            background-color: #F4FBFF;
            position: relative;
        }

        .foot-title {
            font-size: 48px;
            color: #2A536B;
            text-align: center;
            padding: 80px 0 40px 0;
            line-height: 1;
            position: relative;
            z-index: 2;
            opacity: 0;
        }

        .foot-bac {
            width: 100%;
            padding-top: 271px;
        }

        .foot-bac-icon {
            width: 125px;
            height: 171px;
            position: relative;
            left: 154px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .supplier-list {
            width: 1230px;
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto;
            opacity: 0;

            .supplier-item {
                width: 175px;
                height: 168px;
                border-radius: 20px;
                background-color: #ffffff;
                border: 1px solid #F3F3F3;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                margin: 0 15px 30px;
                padding: 20px 0 30px 0;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                transition: all .5s;
                cursor: pointer;
                .supplier-item-cover {
                    width: 150px;
                    height: 90px;
                    text-align: center;
                    transition: all 0.5s;
                }
                img {
                    width: 100%;
                }

                .square {
                    border-radius: 5px;
                    width: 100%;
                }

                p {
                    width: 100%;
                    text-align: center;
                    color: #2A536B;
                    font-size: 16px;
                }
            }
            .supplier-item:hover .supplier-item-cover{
                transform: scale(1.1);
            }
        }

        .foot-list-wrap {
            margin: 209px 0 0 360px;
            width: 668px;

            .foot-list {
                display: flex;
                justify-content: space-between;

                .foot-item {
                    width: 116px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    p {
                        width: 100%;
                        text-align: center;
                    }

                    .foot-item-title {
                        font-size: 16px;
                        color: #FFFFFF;
                        line-height: 32px;
                    }

                    .foot-item-text {
                        font-size: 14px;
                        color: #ffffff;
                        line-height: 24px;
                    }

                    img {
                        width: 36px;
                        height: 36px;
                    }
                }
            }

            .foot-list-btn {
                width: 400px;
                height: 60px;
                background-color: #fff;
                border-radius: 30px;
                text-align: center;
                line-height: 60px;
                font-size: 28px;
                color: #00A2FF;
                margin: 60px auto 0;
                cursor: pointer;
                opacity: 0;
            }
        }

        .foot-bottom {
            height: 71px;
            width: 100%;
            background-color: #0C9CEF;
            position: absolute;
            bottom: 0;
            left: 0;
            padding-top: 15.5px;
            box-sizing: border-box;

            p {
                width: 100%;
                text-align: center;
                font-size: 12px;
                color: #ffffff;
                line-height: 20px;
				span {
					padding: 0 20px;
				}
            }
        }
    }

    /* 隐藏滚动条 */
    .home-footer::-webkit-scrollbar { /*滚动条整体样式*/
        width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 0px;
    }


    @media screen and (max-width: 1440px) {
        .home-body .video-play-btn img {
            width: 72px;
            height: 72px;
        }
        .home-body .video-msg {
            bottom: 100px;
        }
        .home-body .video-msg .download-btn {
            width: 150px;
            height: 45px;
            font-size: 21px;
            line-height: 45px;
        }
        .course-bac img {
            width: 100%;
        }
        .course-wraper .course-title {
            font-size: 36px;
            padding: 59px 0 45px 0;
        }

        .course-wraper .course-list .course-item {
            width: 207px;
            height: 368px;
            .course-item-body{
                width: 207px;
                height: 368px;
            }
            .course-item-title {
                padding: 30px 0 22px 0;
                font-size: 24px;
            }
            .course-item-text {
                font-size: 14px;
                line-height: 28px;
            }
        }
        .various-wraper {
            .various-title {
                font-size: 36px;
                top: 60px;
            }
            .various-icon-a {
                width: 99px;
                height: 94px;
            }
            .various-icon-b {
                width: 87px;
                height: 86px;
            }
            .various-icon-c {
                width: 79px;
                height: 83px;
            }
            .various-icon-d {
                width: 77px;
                height: 83px;
            }
            .various-icon-e {
                width: 95px;
                height: 89px;
            }
            .various-icon-f {
                width: 71px;
                height: 75px;
            }
            .various-icon-g {
                width: 98px;
                height: 96px;
            }
        }
        .team-wraper {
            .team-bac {
                width: 1077px;
                height: 351px;
                position: relative;
                z-index: 9;
            }
            .team-text {
                font-size: 14px;
                padding-top: 38px;
                line-height: 28px;
            }
            .team-title {
                font-size: 36px;
                padding: 60px 0 30px 0;
            }
        }
        .home-footer {
            .foot-bac-icon {
                top: -50px;
                left: 125px;
            }
            .home-footer-wraper {
                height: 1003px;
                -webkit-background-size: 1440px;
                background-size: 1440px;
                background-position: left 203px;
            }
            .foot-title {
                font-size: 36px;
                padding: 60px 0 30px 0;
            }
            .supplier-list {
                width: 966px;
                .supplier-item {
                    width: 131px;
                    height: 126px;
                    padding: 23px 0;
                    .supplier-item-cover {
                        width: 60px;
                        height: 60px;
                    }
                    p {
                        font-size: 12px;
                    }
                }
            }
            .foot-list-wrap {
                width: 600px;
                margin: 35px 0 0 245px;
                .foot-list-btn {
                    width: 150px;
                    height: 45px;
                    line-height: 45px;
                    font-size: 21px;
                }
            }
        }

    }

	.video-player-wraper {
		width: 100vw;
		height: 100vh;
		background-color: rgba(0,0,0,0.8);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;
		.video-player {
			width: 1230px;
			height: 692px;
			background-color: red;
			position: relative;
			.dialog-video {
				width: 100%;
				height: 100%;
			}
			.video-close {
				width: 26px;
				height: 26px;
				position: absolute;
				top: 0;
				right: -66px;
				cursor: pointer;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	@media screen and (min-width: 1920px) {
		.home-footer .home-footer-wraper {
			background-size: 100%;
		}
	}
</style>
